import * as React from 'react'
import { WA_NUMBER } from '@constants/index'
import { useScreenSize } from '@shared/hooks/use-screen-size'
import { yaCounter } from '@shared/pipes/ya-counter'
import WhatsApp from '@shared/svg/whats-app-2.svg'
import clsx from 'clsx'

import styles from './style.module.scss'

const link = `https://wa.me/${WA_NUMBER}?text=Хочу%20оставить%20заявку%20на%20заём%20в%20Credit.Club`

interface Props {
	isWhatsAppShow: boolean
}

export const View = ({ isWhatsAppShow }: Props) => {
	const prevPos = React.useRef(0)
	const scrollTimeout = React.useRef(0)

	const size = useScreenSize()

	const [scrollStatus, setScrollStatus] = React.useState<'idle' | 'scrollingDown' | 'scrollingUp'>(
		'idle'
	)

	React.useEffect(() => {
		prevPos.current = window.scrollY

		const cb = () => {
			window.clearTimeout(scrollTimeout.current)

			if (window.scrollY > prevPos.current) {
				setScrollStatus('scrollingDown')
			} else if (window.scrollY < prevPos.current) {
				setScrollStatus('scrollingUp')
			}

			prevPos.current = window.scrollY

			scrollTimeout.current = window.setTimeout(function () {
				setScrollStatus('idle')
			}, 1000)
		}

		window.addEventListener('scroll', cb)

		return () => {
			window.removeEventListener('scroll', cb)
		}
	}, [])

	const animateWrapperClassName = size.isExtraMobile
		? { [styles.animateLogo]: scrollStatus === 'scrollingUp' }
		: undefined
	const animateButtonClassName = size.isExtraMobile
		? { [styles.animateButton]: scrollStatus !== 'scrollingUp' }
		: styles.buttonVisible

	const onClick = () => {
		yaCounter.utils.reachGoal('wa_application_form')
	}

	if (!isWhatsAppShow) {
		return null
	}

	return (
		<div className={clsx(styles.whatsApp, animateWrapperClassName)}>
			<a href={link} onClick={onClick} target='_blank' rel='noopener noreferrer nofollow'>
				<WhatsApp className={clsx(styles.logo)} />
			</a>
			<a
				href={link}
				onClick={onClick}
				target='_blank'
				rel='noopener noreferrer nofollow'
				className={clsx(styles.chatApp, animateButtonClassName)}
			>
				Заявка в чате
			</a>
		</div>
	)
}
