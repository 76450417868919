import { GetServerSideProps, GetServerSidePropsResult } from 'next'

import { CITY_COOKIE_KEY, CITY_DETECTED_COOKIE_KEY, regions } from './list'
import { cookies } from 'next/headers'

const getRegionAliasIfValid = (candidate?: string): string | null => {
	return candidate ? (regions[candidate] ? (candidate as string) : null) : null
}

interface Cookie {
	[x: string]: string
}

export const getAppliedCityFromCookies = (cookies: Cookie): string | null => {
	return getRegionAliasIfValid(cookies[CITY_COOKIE_KEY])
}

export const getDetectedCityFromCookies = (cookies: Cookie): string | null => {
	return getRegionAliasIfValid(cookies[CITY_DETECTED_COOKIE_KEY])
}

export const serverSideRegionRedirectFactory = (
	city: string,
	extraUrl: string,
	isPermanent?: boolean
): GetServerSidePropsResult<any> => ({
	props: {},
	redirect: {
		permanent: !!isPermanent,
		destination: `/${city}${extraUrl}`,
	},
})

export const notFoundSSP: GetServerSideProps<any> = async (context) => {
	context.res.statusCode = 404
	return { props: {} }
}
